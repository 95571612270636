var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0 proposta" },
        [
          _c("breadcrumb", {
            attrs: {
              title: "CCG - Contrato de conta garantia",
              actualPage: "Contrato de conta garantia"
            }
          })
        ],
        1
      ),
      _c(
        "vs-card",
        { staticClass: "px-4 pb-20 mt-6" },
        [
          _c("h3", { staticClass: "mt-5" }, [
            _vm._v("Representantes legais do tomador")
          ]),
          _vm._l(_vm.listaLepresentantes, function(representante, index) {
            return _c(
              "div",
              { key: representante._id, staticClass: "vx-row mt-8" },
              [
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/4" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "###.###.###-##",
                          expression: "'###.###.###-##'"
                        }
                      ],
                      staticClass: "w-full",
                      attrs: { label: "CPF", size: "large" },
                      model: {
                        value: representante.CPF,
                        callback: function($$v) {
                          _vm.$set(representante, "CPF", $$v)
                        },
                        expression: "representante.CPF"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/3" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Nome", size: "large" },
                      model: {
                        value: representante.Name,
                        callback: function($$v) {
                          _vm.$set(representante, "Name", $$v)
                        },
                        expression: "representante.Name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/3" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "E-mail", size: "large" },
                      model: {
                        value: representante.Email,
                        callback: function($$v) {
                          _vm.$set(representante, "Email", $$v)
                        },
                        expression: "representante.Email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/12" },
                  [
                    _c("vs-button", {
                      staticClass: "mt-6",
                      attrs: { size: "large", icon: "delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteRepresentante(index)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          _c("div", { staticClass: "vx-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "font-semibold mt-2 float-right",
                    attrs: {
                      "icon-after": "",
                      color: "primary",
                      type: "flat",
                      icon: "add_circle"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addNewRepresentante()
                      }
                    }
                  },
                  [_vm._v("\n          Incluir representante legal\n        ")]
                )
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }